



































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignItem } from '@/models/CampaignItem';

@Component({})
export default class CampaignListPanel extends Vue {
  @Prop({ required: true })
  readonly campaign!: CampaignItem;

  isMultipleCardType = false;

  get cardType() {
    return this.$store.state.microCMS.cardType;
  }

  get permitCardTypeText() {
    if (this.campaign.isPermitTargetAll) {
      return '全券種';
    }
    if (this.campaign.permitCardTypeText) {
      return this.campaign.permitCardTypeText;
    }
    if (this.campaign.isPermitTargetGroup) {
      this.isMultipleCardType = this.campaign.permitCardTypeGroups.length > 1;
      return this.campaign.permitCardTypeGroups[0].name;
    }
    if (this.campaign.isPermitTargetSingle) {
      this.isMultipleCardType = this.campaign.permitCardTypes.length > 1;
      return this.campaign.permitCardTypes[0].name;
    }
    return '';
  }

  get url(): string {
    const params = new URLSearchParams();
    params.append('id', this.campaign.campaignId);
    return '/campaign?' + params.toString();
  }
}
