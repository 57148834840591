



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import CampaignListPanel from '@/components/Campaign/CampaignListPanel.vue';
import ContactPanel from '@/components/ContactPanel.vue';
import { CampaignRepository } from '@/repositories/CampaignRepository';
import { CampaignItem } from '@/models/CampaignItem';

@Component({
  components: {
    CampaignListPanel,
    ContactPanel
  }
})
export default class P0195 extends Vue {
  readonly INITIAL_PAGE_NO = 1;
  readonly LIMIT_NUM = 10; //1度に表示するキャンペーンの件数
  campaignList: Array<CampaignItem> = [] as Array<CampaignItem>;
  totalCount: number = 0;
  pageNo: number = this.INITIAL_PAGE_NO;

  async getCampaignList() {
    const listItem = await this.campaignRepository.get(
      this.LIMIT_NUM,
      this.LIMIT_NUM * (this.pageNo - 1)
    );
    if (listItem) {
      this.campaignList = listItem.items;
      this.totalCount = listItem.totalCount;
    }
  }

  get campaignRepository() {
    return new CampaignRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  @Watch('pageNo', { immediate: true })
  onChangePageNo() {
    this.getCampaignList();
  }

  nextPage() {
    if (!this.isLastPage) {
      this.pageNo++;
    }
  }

  prevPage() {
    if (!this.isFirstPage) {
      this.pageNo--;
    }
  }

  get isLastPage() {
    return this.totalCount < this.LIMIT_NUM * this.pageNo + 1;
  }

  get isFirstPage() {
    return this.pageNo === this.INITIAL_PAGE_NO;
  }
}
